















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'

@Component
export default class AlbumDescriptionManager extends Vue {
  description = ''
  backupDescription = ''
  isTextAreaDisplayed = false

  mounted() {
    this.updateDescription()
  }

  editDescription() {
    if (this.hasEditPermission) {
      this.isTextAreaDisplayed = true
      this.backupDescription = this.description
      setTimeout(() => {
        document.getElementById('edit')?.focus()
      }, 50)
    }
  }

  cancelEdit() {
    this.description = this.backupDescription
    setTimeout(() => {
      this.isTextAreaDisplayed = false
    }, 50)
  }

  submitDescription() {
    setTimeout(() => {
      this.isTextAreaDisplayed = false
      this.$store.dispatch('firestore/updateAlbumDescription', this.description)
    }, 50)
  }

  @Watch('selectedAlbum')
  updateDescription() {
    if (this.selectedAlbum?.description) {
      this.description = this.selectedAlbum?.description
    }
  }

  get selectedAlbum() {
    return vxm.firestore.selectedAlbum
  }

  get hasEditPermission() {
    return vxm.user.hasEditPermission
  }
}
