



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'
import fb from 'firebase/app'
import Photo = fb.firestore.DocumentData
import Check from '@/components/Check.vue'
import firebase from '../services/firebase'

@Component({ components: { Check } })
export default class PhotoChooser extends Vue {
  firebaseTarget: fb.firestore.CollectionReference<Photo> = firebase.homepage
  selected: Photo[] = []
  isDisplayingAlbumId = ''

  mounted() {
    this.$store.dispatch('firestore/getAlbums')
  }

  selectPhoto(photo: Photo) {
    if (!this.isPhotoSelected(photo)) {
      this.selected.push(photo)
    } else {
      const index = this.selected.indexOf(photo)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
    }
  }

  displayPhotos(albumId: string) {
    if (albumId === this.isDisplayingAlbumId) {
      this.isDisplayingAlbumId = ''
    } else {
      this.isDisplayingAlbumId = albumId
    }
  }

  cancel() {
    this.$modal.hideAll()
  }

  add() {
    this.selected.forEach(photo => {
      console.log(this.currentComponent)
      if (this.currentComponent === 'gallery') {
        this.$store.dispatch('firestore/addGalleryPhotos', photo)
        this.$store.dispatch('firestore/getGalleryPhotos')
      } else if (this.currentComponent === 'homepage') {
        this.$store.dispatch('firestore/addHomepagePhotos', photo)
        this.$store.dispatch('firestore/getHomepagePhotos')
      }
    })
    this.$modal.hideAll()
  }

  @Watch('selected')
  isPhotoSelected(photo: Photo): boolean {
    return this.selected.includes(photo)
  }

  get albums() {
    return vxm.firestore.albums
  }

  get currentComponent() {
    return vxm.app.selectedPhotoManagerComponent
  }
}
