






















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AlbumDescriptionManager from '@/components/AlbumDescriptionManager.vue'
import Check from './Check.vue'
import firebase from '../services/firebase'
import fb from 'firebase/app'
import FieldValue = fb.firestore.FieldValue
import Photo = fb.firestore.DocumentData
import { PhotoUpload } from '../types/PhotoUpload'
import { vxm } from '@/store/store.vuex'
import PhotoChooser from './PhotoChooser.vue'

@Component({
  components: { Check, AlbumDescriptionManager, PhotoChooser }
})
export default class Albums extends Vue {
  previewPhotos: PhotoUpload[] = []
  selected: Photo[] = []
  firebaseTarget: fb.firestore.CollectionReference<Photo> = firebase.homepage
  getFirestorePhotosAction = ''
  getFirestorePhotosActionParam = ''
  photos: Photo[] = []

  created() {
    this.updateValues()
  }

  addFile() {
    this.$modal.show(PhotoChooser, { width: 300, height: 300 }, { 'before-close': this.beforeModalClose })
  }

  beforeModalClose() {
    this.$store.dispatch(this.getFirestorePhotosAction, this.getFirestorePhotosActionParam)
  }

  onFileChange() {
    const files: ReadonlyArray<File> = [...(this.upload.files ? this.upload.files : [])]
    let index = 0
    files.forEach(file => {
      this.previewPhotos.push({ url: null, progress: 0 })

      const storageRef = firebase.storage.ref(`${file.name}`).put(file)
      storageRef.on(
        `state_changed`,
        () => {
          this.previewPhotos[index].progress = this.previewPhotos[index].progress + 5
        },
        error => {
          console.log(error.message)
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.previewPhotos[index].url = url
            const id = 'photo' + Date.now()
            if (this.currentComponent !== 'albums') {
              this.firebaseTarget.doc(id).set({ id, name: file.name, url })
            } else {
              this.firebaseTarget.doc(this.getFirestorePhotosActionParam).update({
                photos: FieldValue.arrayUnion({ id, name: file.name, url: url })
              })
            }
            if (index === files.length - 1) {
              this.$store.dispatch(this.getFirestorePhotosAction, this.getFirestorePhotosActionParam)
              this.previewPhotos = []
              return
            }
            index++
          })
        }
      )
    })
  }

  /** Upload element */
  get upload(): HTMLInputElement {
    return this.$refs.fileUpload as HTMLInputElement
  }

  deleteImages() {
    if (this.currentComponent !== 'albums') {
      this.selected.forEach((photo, index) => {
        this.firebaseTarget
          .doc(photo?.id)
          .delete()
          .then(() => {
            if (index === this.selected.length - 1) {
              this.selected = []
              this.$store.dispatch(this.getFirestorePhotosAction, this.getFirestorePhotosActionParam)
              this.$swal({
                position: 'bottom',
                text: this.$tc('album.photoDeleted', this.selected.length),
                showConfirmButton: false,
                timer: 1500
              })
            }
          })
      })
    } else {
      this.selected.forEach((photo, index) => {
        this.firebaseTarget
          .doc(this.getFirestorePhotosActionParam)
          .update({
            photos: FieldValue.arrayRemove({ id: photo.id, name: photo.name, url: photo.url })
          })
          .then(() => {
            if (index === this.selected.length - 1) {
              this.$store.dispatch(this.getFirestorePhotosAction, this.getFirestorePhotosActionParam)
              this.$swal({
                position: 'bottom',
                text: this.$tc('album.photoDeleted', this.selected.length),
                showConfirmButton: false,
                timer: 1500
              })
              this.selected = []
            }
          })
      })
    }
  }

  selectPhoto(photo: Photo) {
    if (!this.isPhotoSelected(photo)) {
      this.selected.push(photo)
    } else {
      const index = this.selected.indexOf(photo)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
    }
  }

  @Watch('selected')
  isPhotoSelected(photo: Photo): boolean {
    return this.selected.includes(photo)
  }

  @Watch('homepagePhotos')
  updateHomepagePhotos() {
    if (this.currentComponent === 'homepage') this.photos = this.homepagePhotos
  }
  get homepagePhotos() {
    return vxm.firestore.homepagePhotos
  }

  @Watch('galleryPhotos')
  updateGalleryPhotos() {
    if (this.currentComponent === 'gallery') this.photos = this.galleryPhotos
  }
  get galleryPhotos() {
    return vxm.firestore.galleryPhotos
  }

  @Watch('albumPhotos')
  updateAlbumsPhotos() {
    if (this.currentComponent === 'albums') this.photos = this.albumPhotos
  }
  get albumPhotos() {
    return vxm.firestore.albumPhotos
  }

  @Watch('currentComponent')
  updateValues() {
    switch (this.currentComponent) {
      case 'homepage':
        this.firebaseTarget = firebase.homepage
        this.getFirestorePhotosAction = 'firestore/getHomepagePhotos'
        break
      case 'gallery':
        this.firebaseTarget = firebase.gallery
        this.getFirestorePhotosAction = 'firestore/getGalleryPhotos'
        break
      case 'albums':
        this.firebaseTarget = firebase.albums
        this.getFirestorePhotosAction = 'firestore/getAlbumPhotos'
        this.getFirestorePhotosActionParam = this.$route.path.split('/').slice(-1)[0]
        break
      default:
        break
    }
    this.$store.dispatch(this.getFirestorePhotosAction, this.getFirestorePhotosActionParam)
  }

  get currentComponent() {
    return vxm.app.selectedPhotoManagerComponent
  }
}
